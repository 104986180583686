<template>
  <div class="meeting">
    <div class="meeting_banner_wraper">
      <div class="meeting_banner_container">
        <div class="meeting_banner_container_title">智能会务</div>
        <div class="meeting_banner_container_desc">
          绿色高效的智能会务管理系统
        </div>
        <div class="meeting_banner_container_detail">
          <span>会议资料管理</span><span>会议室管理</span
          ><span>会议日程管理</span><span>会议资料阅读</span>
        </div>
        <div class="meeting_banner_container_btn">在线咨询</div>
      </div>
    </div>
    <div class="meeting_user">
      <div class="meeting_user_title">用户痛点</div>
      <div class="meeting_user_desc">
        在传统的会议模式下，办会处处伴随着低效……
      </div>
      <div class="meeting_user_item">
        <div
          class="meeting_user_item_li"
          v-for="(item, index) in list"
          :key="index"
        >
          <div class="meeting_user_item_li_img">
            <img :src="item.icon" alt="" />
          </div>
          <div class="meeting_user_item_li_title">{{ item.title }}</div>
          <div class="meeting_user_item_li_desc">{{ item.desc }}</div>
          <div class="meeting_user_item_li_desc">{{ item.info }}</div>
        </div>
      </div>
      <div class="meeting_tab">
        <div class="meeting_tab_title">
          <div
            class="meeting_tab_title_item"
            :class="{ active: activeIndex === 1 }"
            @mouseenter="changeIndex(1)"
          >
            功能架构
          </div>
          <div
            class="meeting_tab_title_item"
            :class="{ active: activeIndex === 2 }"
            @mouseenter="changeIndex(2)"
          >
            应用流程
          </div>
        </div>

        <div class="meeting_tab_change">
          <div class="meeting_tab_change_item" v-if="activeIndex === 1">
            <div class="meeting_tab_change_item_title">
              智能会务，颠覆传统的会议模式
            </div>
            <div class="meeting_tab_change_item_desc">
              这是一款覆盖会务管理全流程的会务管理系统，可以和本公司的办公自动化系统进行无缝对接，形成会议议题呈批、会议资料准备、参会、
              会后资料存档的一体化会务管理。能够为党政单位提供专业的会务管理，帮助政府单位摒弃传统的会务模式，打造高效率、低能耗的绿色会务模式。
            </div>
            <div class="meeting_tab_change_item_img1">
              <img src="@/assets/images/solve/meeting/img1.png" alt="" />
            </div>
          </div>
          <div
            class="meeting_tab_change_item meeting_tab_change_item_2"
            v-if="activeIndex === 2"
          >
            <div class="meeting_tab_change_item_img2">
              <img src="@/assets/images/solve/meeting/img2.png" alt="" />
            </div>
          </div>
        </div>
      </div>

      <div class="meeting_case">
        <div class="meeting_case_title">解决方案</div>
        <div class="meeting_case_desc">使用智能会务系统，会议难题迎刃而解</div>
        <div class="meeting_case_wraper">
          <span v-for="i in 5" :key="i + randomId(1)"></span>
          <div
            class="meeting_case_item"
            :class="{ active: liIndex === 1 }"
            @mouseenter="change(1)"
          >
            <img :src="liIndex === 1 ? fontActiveIcon : fontIcon" alt="" />
            会前
          </div>
          <span v-for="i in 5" :key="i + randomId(2)"></span>
          <div
            class="meeting_case_item"
            :class="{ active: liIndex === 2 }"
            @mouseenter="change(2)"
          >
            <img :src="liIndex === 2 ? middleActiveIcon : middleIcon" alt="" />
            会中
          </div>
          <span v-for="i in 5" :key="i + randomId(3)"></span>
          <div
            class="meeting_case_item"
            :class="{ active: liIndex === 3 }"
            @mouseenter="change(3)"
          >
            <img :src="liIndex === 3 ? endActiveIcon : endIcon" alt="" />
            会后
          </div>
          <span v-for="i in 5" :key="i + randomId"></span>
        </div>
        <div class="meeting_case_font" v-if="liIndex === 1">
          <div
            class="meeting_case_list_li"
            v-for="(item, index) in meetingList"
            :key="index"
          >
            <img :src="item.img" alt="" />
            <div class="meeting_case_list_li_title">{{ item.title }}</div>
            <div class="meeting_case_list_li_desc" v-html="item.desc"></div>
          </div>
        </div>
        <div class="meeting_case_middle" v-if="liIndex === 2">
          <div class="meeting_case_middle_img">
            <img src="@/assets/images/solve/meeting/mi.png" alt="" />
          </div>
          <div class="meeting_case_middle_right">
            <div class="meeting_case_middle_right_item">
              <img src="@/assets/images/solve/meeting/eye.png" alt="" />
              <div class="meeting_case_middle_right_title">电子阅读</div>
              <div class="meeting_case_middle_right_desc">
                会议全程无纸化，使用电子设备阅读<br />
                会议资料，节约大量打印耗材、纸张和人力。
              </div>
            </div>
            <div class="meeting_case_middle_right_item">
              <img src="@/assets/images/solve/meeting/edit.png" alt="" />
              <div class="meeting_case_middle_right_title">灵活修改</div>
              <div class="meeting_case_middle_right_desc">
                会议议题资料新增、修改、删除后，<br />
                可一键同步到会议阅读设备上。
              </div>
            </div>
          </div>
        </div>
        <div class="meeting_case_end" v-if="liIndex === 3">
          <div class="meeting_case_middle_img">
            <img src="@/assets/images/solve/meeting/e.png" alt="" />
          </div>
          <div class="meeting_case_middle_right">
            <div class="meeting_case_middle_right_item">
              <img src="@/assets/images/solve/meeting/wd.png" alt="" />
              <div class="meeting_case_middle_right_title">资料归档</div>
              <div class="meeting_case_middle_right_desc">
                会后会议资料自动分类<br />
                归档到会议资料管理系统。
              </div>
            </div>
            <div class="meeting_case_middle_right_item">
              <img src="@/assets/images/solve/meeting/gx.png" alt="" />
              <div class="meeting_case_middle_right_title">资料更新</div>
              <div class="meeting_case_middle_right_desc">
                会议资料存档后，会议设备上的资料<br />
                将被自动删除，防止外传。
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="meeting_hardword">
      <div class="meeting_list">
        <div class="meeting_list_title">适配硬件</div>
        <div class="meeting_list_wraper">
          <div
            class="meeting_list_item"
            v-for="(item, index) in imgList"
            :key="index"
          >
            <img :src="item.img" alt="" class="img" />
            <div class="meeting_list_item_title">{{ item.title }}</div>
          </div>
        </div>
      </div>
    </div>
    <div class="meeting_footer">
      <div class="meeting_footer_title">客户案例</div>
      <div class="meeting_footer_desc">
        佛山市人社局常务会议、佛山市高明区区委常务会议<br />
        佛山市三水区水务局、经促局、经科局、南山镇、西南街道、医保局、区委区政府<br />
        延安市富县县委、县政府、延安市吴起县委、县政府
      </div>
      <div class="meeting_footer_item">
        <div class="meeting_footer_item_img img1">
          <img src="@/assets/images/solve/meeting/f1.png" alt="" />
        </div>
        <div class="meeting_footer_item_img img2">
          <img src="@/assets/images/solve/meeting/f2.png" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from 'jquery'
export default {
  name: '',
  components: {

  },
  props: {

  },
  data () {
    return {
      fontIcon: require('@/assets/images/solve/meeting/会前_nor.png'),
      fontActiveIcon: require('@/assets/images/solve/meeting/会前_yes.png'),
      middleIcon: require('@/assets/images/solve/meeting/会议_nor.png'),
      middleActiveIcon: require('@/assets/images/solve/meeting/会议_yes.png'),
      endIcon: require('@/assets/images/solve/meeting/会后_nor.png'),
      endActiveIcon: require('@/assets/images/solve/meeting/会后_yes.png'),
      activeIndex: 1,
      liIndex: 1,
      list: [
        {
          icon: require('@/assets/images/solve/meeting/icon1.png'),
          title: '议题准备慢',
          desc: '开会准备流程长，',
          info: '领导签批与议题上会不能联动'
        }, {
          icon: require('@/assets/images/solve/meeting/icon2.png'),
          title: '会议室管理难',
          desc: '会场使用易冲突，',
          info: '没有形成数字化统一管理'
        }, {
          icon: require('@/assets/images/solve/meeting/icon3.png'),
          title: '议题资料更改麻烦',
          desc: '议题资料不能随时修改，',
          info: '修改后需重新走流程、打印、分发'
        }, {
          icon: require('@/assets/images/solve/meeting/icon4.png'),
          title: '会议通知靠人',
          desc: '会议通知主要靠电话，',
          info: '备会人员通过反复沟通确认参会名单'
        }, {
          icon: require('@/assets/images/solve/meeting/icon5.png'),
          title: '会议成本高昂',
          desc: '会议打印资料需耗费大量纸张，',
          info: '无法循环利用，造成浪费'

        }, {
          icon: require('@/assets/images/solve/meeting/icon6.png'),
          title: '议题准备慢',
          desc: '会议资料分类归档缺乏管理，',
          info: '安全保密性差'
        }
      ],
      meetingList: [
        {
          img: require('@/assets/images/solve/meeting/ld.png'),
          title: '签批联动',
          desc: '系统与OA无缝对接，议题呈批后自动<br/>形成上会议题，<br/>会前一键同步到会务系统。<br/>'
        },
        {
          img: require('@/assets/images/solve/meeting/hc.png'),
          title: '会场信息',
          desc: '系统与OA无缝对接，议题呈批后自动<br/>形成上会议题，<br/>会前一键同步到会务系统。<br/>'
        },
        {
          img: require('@/assets/images/solve/meeting/lb.png'),
          title: '会议类别',
          desc: '管理本单位的会议类型，将会议<br/>分类管理，方便查档时快速定位和查找。<br/>'
        },
        {
          img: require('@/assets/images/solve/meeting/hy.png'),
          title: '会议信息',
          desc: '备会人员填写会议信息，<br/>控制参会设备上获取会议信息的权限。'
        },
        {
          img: require('@/assets/images/solve/meeting/zl.png'),
          title: '议题资料',
          desc: '会议议题、<br/>资料顺序随时调整，灵活修改。'
        },
        {
          img: require('@/assets/images/solve/meeting/rc.png'),
          title: '会场日程',
          desc: '备会人员创建会议后<br/>自动生成会议日程并可跟进查看。'
        },
        {
          img: require('@/assets/images/solve/meeting/bm.png'),
          title: '通知报名',
          desc: '会议确定后，系统自动通知参会单位，<br/>参会人员线上报名，自动生成参会名单。'
        },
        {
          img: require('@/assets/images/solve/meeting/gz.png'),
          title: '工作日程',
          desc: '会议创建后，系统自动生成工作日程，<br/>领导秘书和参会人员可随时跟进。'
        }
      ],
      imgList: [
        {
          img: require('@/assets/images/solve/meeting/h1.png'),
          title: '会务资料管理服务器'
        },
        {
          img: require('@/assets/images/solve/meeting/h2.png'),
          title: '参会设备'
        },
        {
          img: require('@/assets/images/solve/meeting/h3.png'),
          title: '参会设备统一充电存储设备'
        }
      ]
    }
  },
  computed: {

  },
  watch: {

  },
  created () {

  },
  mounted () {
    window.addEventListener('scroll', this.slide)
    setTimeout(() => {
      $('.meeting_user_item_li').addClass('animation')
    }, 100)
  },
  destroyed () {
    window.removeEventListener('scroll', this.slide)
  },
  methods: {
    slide () {
      const h = document.documentElement.scrollTop || document.body.scrollTop || window.pageYOffset
      const targetHeight = $('.meeting_footer_item').offset().top
      const imgHeight = $('.meeting_list_item').offset().top

      if (h > targetHeight - 1000) {
        $('.meeting_footer_item_img').addClass('slide')
      }
      if (h > imgHeight - 800) {
        $('.img').addClass('scaleImg')
      }
    },
    randomId (id) {
      return (Math.random() * 1000).toFixed(id)
    },
    changeIndex (id) {
      this.activeIndex = id
    },
    change (id) {
      this.liIndex = id
    }
  }
}
</script>

<style scoped lang="scss">
@import './style/index.scss';
.fade-enter-active,
.fade-leave-active {
  transition: opacity 1s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
